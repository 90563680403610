// -----------------------------------------------------------------------------
// This file contains all styles related to the FORMS components.
// -----------------------------------------------------------------------------

// ==== Form Label ==== //

.form-label {
  display: block;
  color: $black;
  line-height: 1.8;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  &._noEllipsis {
    white-space: normal;
    line-height: 1.3;
    margin: {
      top: rem-calc(6px);
      bottom: rem-calc(9px);
    };
  }
}

// === Invalide

select, ng-select {
  &.ng-invalid {
    &._form_submitted {
      border-color: $app-error-color;
      box-shadow: 0 0 3px $app-error-color;
    }
  }
}

date-picker {
  &.ng-invalid {
    input:focus, input {
      border-color: $app-error-color;
      box-shadow: 0 0 3px $app-error-color;
    }
  }
}

// ==== Status Label Select ==== //

.status-list-select {
  margin: 0;

  .status-list-select-item {
    position: relative;
    display: block;
    margin-bottom: rem-calc(12px);

    &.selected {
      .state-neutral {
        background: $primary;
        color: white !important;
      }

      .state-MISSING {
        background: $status-MISSING;
        color: white !important;
      }

      .state-OUTDATED {
        background: $status-OUTDATED;
        color: white !important;
      }

      .state-UP_TO_DATE {
        background: $status-UPTODATE;
        color: white !important;
      }

      .state-TO_CHECK {
        background: $status-TOCHECK;
        color: white !important;
      }
    }

    .radio {
      width: 100%;

      .form-label {
        width: 100%;
      }
    }

    .info-tooltip {
      position: absolute;
      top: 50%;
      right: rem-calc(16px);
      transform: translate(0, -50%);
      height: rem-calc(24px);
      color: $grey-medium;
      cursor: pointer;
      font-size: rem-calc(24px);
      @extend %globalTransition;

      &:hover, &:active {
        @extend %globalTransition;
        cursor: $grey-dark;
      }
    }

    .tooltip {
      width: rem-calc(200px);
      height: auto;
      white-space: normal;
    }

    .status-label-select {
      display: inline-block;
      vertical-align: middle;
      width: calc(100% - 94px);
      margin-left: rem-calc(8px);
      font-size: rem-calc(14px);
      text-align: center;
      border-radius: rem-calc(4px);
      font-weight: 500;
      cursor: pointer;
      border: rem-calc(2px) solid $primary;
      color: $primary;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      // ==== Status Select List

      &.state-neutral {
        border-color: $primary;
        color: $primary;

        &.selected {
          background: $primary;
          color: white !important;
        }
      }

      &.state-MISSING {
        border-color: $status-MISSING;
        color: $status-MISSING;

        &.selected {
          background: $status-MISSING;
          color: white !important;
        }
      }

      &.state-OUTDATED {
        border-color: $status-OUTDATED;
        color: $status-OUTDATED;

        &.selected {
          background: $status-OUTDATED;
          color: white !important;
        }
      }

      &.state-UP_TO_DATE {
        border-color: $status-UPTODATE;
        color: $status-UPTODATE;

        &.selected {
          background: $status-UPTODATE;
          color: white !important;
        }
      }

      &.state-TO_CHECK {
        border-color: $status-TOCHECK;
        color: $status-TOCHECK;

        &.selected {
          background: $status-TOCHECK;
          color: white !important;
        }
      }
    }
  }
}

select,
textarea {
  &:disabled {
    background-color: $input-background-disabled !important;

    &:hover {
      background-color: $input-background-disabled !important;
    }
  }
}

select:required {
  &:disabled {
    &:hover {
      background-image: url($common-png-path + 'select-required.png') !important;
    }
  }
}

.formTitle {
  color: $app-grey_dark;
  line-height: 2.4;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: bold;
}

// ==== Status Label Select ==== //

.status-list-select {
  margin: 0;

  .status-list-select-item {
    position: relative;
    display: block;
    margin-bottom: rem-calc(12px);

    &.selected {
      .state-neutral { background: $primary; color: white !important;}
      .state-MISSING { background: $status-MISSING; color: white !important;}
      .state-OUTDATED, .state-NOT_CONFORMS { background: $status-OUTDATED; color: white !important;}
      .state-UP_TO_DATE, .state-CONFORMS { background: $status-UPTODATE; color: white !important;}
      .state-TO_CHECK, .state-PARTIAL_CONFORMS { background: $status-TOCHECK; color: white !important;}
      .state-NOT_SUITABLE { background: $status-NOT_SUITABLE; color: white !important;}
    }

    .radio { width: 100%; .form-label {width: 100%;}}

    .info-tooltip {
      position: absolute;
      top: 50%;
      right: rem-calc(16px);
      transform: translate(0, -50%);
      height: rem-calc(24px);
      color: $grey-medium;
      cursor: pointer;
      font-size: rem-calc(24px);
      @extend %globalTransition;

      &:hover, &:active {
        @extend %globalTransition;
        cursor: $grey-dark;
      }
    }

    .tooltip {
      width: rem-calc(200px);
      height: auto;
      white-space: normal;
    }

    .status-label-select {
      display: inline-block;
      vertical-align: middle;
      width: calc(100% - 94px);
      margin-left: rem-calc(8px);
      font-size: rem-calc(14px);
      text-align: center;
      border-radius: rem-calc(4px);
      font-weight: 500;
      cursor: pointer;
      border: rem-calc(2px) solid $primary;
      color: $primary;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      // ==== Status Select List

      &.state-neutral { border-color: $primary; color: $primary;  &.selected { background: $primary; color: white !important;}}
      &.state-MISSING { border-color: $status-MISSING; color: $status-MISSING; &.selected { background: $status-MISSING; color: white !important;}}
      &.state-OUTDATED, &.state-NOT_CONFORMS { border-color: $status-OUTDATED; color: $status-OUTDATED;  &.selected { background: $status-OUTDATED; color: white !important;}}
      &.state-UP_TO_DATE, &.state-CONFORMS { border-color: $status-UPTODATE; color: $status-UPTODATE;  &.selected { background: $status-UPTODATE; color: white !important;}}
      &.state-TO_CHECK, &.state-PARTIAL_CONFORMS { border-color: $status-TOCHECK; color: $status-TOCHECK;  &.selected { background: $status-TOCHECK; color: white !important;}}
      &.state-NOT_SUITABLE { border-color: $status-NOT_SUITABLE; color: $status-NOT_SUITABLE;  &.selected { background: $status-NOT_SUITABLE; color: white !important;}}
    }
  }
}
