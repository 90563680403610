/**
 *  Custom foundation cards
 */

.card {
  .card-footer {
    padding: rem-calc(0 12px 12px 12px);
  }

  .card-divider {

    &._primary {
      background: $app-primary;
    }

    &._special {
      background: $app-special;
    }

    &._hasGrid {
      display: block;
    }
  }
}
