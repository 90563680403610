// -----------------------------------------------------------------------------
// This file contains all styles related to the TABLES components.
// -----------------------------------------------------------------------------

// ==== Custom Tables (principal settings in file foundation-settings.scss)  ==== //

table {
  margin: 0;

  &._noBg {
    tr {
      background: transparent;
      border-bottom: rem-calc(1px) solid $lineGrey;

      &:nth-of-type(even) {
        border-bottom: rem-calc(1px) solid $lineGrey;
      }
    }
  }

  &.hover {

    thead {
      tr {
        cursor: default;

        td {background: white;}
      }
    }

    &:not(.unstriped) tr:nth-of-type(even):hover {
      background-color: #f9f9f9;
    }

    tr {
      cursor: pointer;

      td {
        line-height: 1.2;
        @extend %globalTransition;
      }
    }
  }

  // ==== Common Table

  tr {
    td {
      i {
        display: inline-block;
        vertical-align: middle;
      }

      &.col-name-file {
        max-width: rem-calc(190px);
        overflow-wrap: break-word;
      }

      &._actionColumn {
        text-align: center;
      }

      &._colActions {
        text-align: right;

        button {
          margin-left: rem-calc(8px);
        }
      }
    }

    // ==== Type Cell

    // == Custom
    &.open {
      .title-folder {
        color: $primary;
        font-weight: bold;
      }

      .btn-collapse {
        background: $primary !important;
        color: white !important;
        border-color: $primary !important;
      }

      .col-reference { color: $primary;}
    }

    // == State
  }

  ._bgShade {
    background-color: rgba($app-primary, 0.04) !important;
  }

  ._bgGrey {
    background-color: $app-grey_light;
  }

  ._bgPrimary {
    background-color: rgba($app-primary, 0.04);
  }

  // ==== Part Tables
  thead {
    tr {
      th {
        font-size: rem-calc(12px);
      }
    }
  }

  tbody {

    &.when-focused {
      @extend %globalTransition;
      position: relative;
      display: inherit;
      width: 100%;
      background: linear-gradient(to right, rgba($primary, 0.4) 0%,rgba(white,0) 34%);

      .col-reference {
        color: $primary;
        font-weight: 500;
        text-decoration: underline;
      }

      &:before {
        position: absolute;
        top: 0;
        left: 0;
        background-color: $primary;
        width: rem-calc(4px);
        height: 100%;
        content: '';
      }
    }

    tr {
      border-bottom: 1px solid $grey-ultra-light;

      &:nth-child(even) { border-bottom: 1px solid $grey-ultra-light;}

      td {
        font-weight: 500;
        line-height: 1.3;

        button { margin-bottom: 0 !important;}

        .col-reference {
          position: relative;
          color: $grey-dark;

          &:after {
            font-family: 'beclm-cu';
            position: absolute;
            top: 50%;
            right: rem-calc(-8px);
            transform: translate(-50%, -50%);
            content: '\e301';
            color: $grey-medium;
            opacity: 0;
            font-size: rem-calc(18px);
            @extend  %globalTransition;
          }

          &:hover, &:active {

            &:after {
              @extend  %globalTransition;
              right: rem-calc(-34px);
              opacity: 1;
            }
          }
        }
      }
    }


    .col-status {
      width: rem-calc(300px);
      text-align: left;
    }
  }

  tfoot {
    th {
      padding-bottom: rem-calc(8px);
    }
    &._bgPrimary {
      background-color: mix($primary, $white, 10%);
      color: $app-primary;
    }
  }
}

.icon-type {
  color: rgba($primary, 0.2);
}

// ==== Collapsed Table  ==== //

.content-sub-cell {
  display: inline-grid;
  width: 100%;

  // === Level Cell

  .first-level-cell {
    display: inline-block;
    width: 100%;
    padding-left: rem-calc(34px);
  }

  .second-level-cell {
    display: inline-block;
    width: 100%;
    padding-left: rem-calc(34px);

    .second-level-cell-tr {
      border-bottom: none;
    }
  }
}

// ==== Form Col  ==== //

.formColAdd {
  border-bottom: rem-calc(1px) solid $lineGrey;

  &:last-child {border-bottom: none;}
}
