/**
 * Helpers class (v.0.1)
 *
 * Table of Contents:
 * 1. Alignment
 * 2. Margin
 * 3. Padding
 * 4. Typography
 * 5. Visibility
 */


/**
 * 1. Alignment
 */
.text-right                             {text-align: right !important;}
.text-center                            {text-align: center !important;}
.text-left                              {text-align: left !important;}
.text-justify                           {text-align: justify !important;}
.clearContainer                         {width: 100%; margin: 0 auto !important;}

/**
 * 2. Margin
 */
.no-margin                              {margin: 0 !important;}
.no-margin-top                          {margin-top: 0 !important;}
.no-margin-right                        {margin-right: 0 !important;}
.no-margin-left                         {margin-left: 0 !important;}
.no-margin-bottom                       {margin-bottom: 0 !important;}

.add-space-right                        {margin-right: rem-calc(8px) !important;}
.add-space-left                         {margin-left: rem-calc(8px) !important;}


/**
 * 3. Padding
 */
.no-padding                            {padding: 0 !important;}
.no-padding-top                        {padding-top: 0 !important;}
.no-padding-right                      {padding-right: 0 !important;}
.no-padding-left                       {padding-left: 0 !important;}
.no-padding-bottom                     {padding-bottom: 0 !important;}


/**
 * 4. Typography
 */
.font-italic                            {font-style: italic !important;}
.font-bold                              {font-weight: bold !important;}
.font-normal                            {font-weight: normal !important;}
.font-light                             {font-weight: lighter !important;}

.text-uppercase                         {text-transform: uppercase;}
.text-lowercase                         {text-transform: lowercase;}
.text-ellipsis                          {text-overflow: ellipsis; overflow: hidden; display: block;}

.word-break                             {word-break: break-all !important;}
.word-block                             {word-break: break-word !important;}
.nowrap                                 {white-space: nowrap;}

/**
 * 5. Visibility
 */
.overflow                                     {overflow: auto;}
.overflow-visible                             {overflow: visible !important;}
.overflow-hidden                              {overflow: hidden;}
.overflow-y                                   {overflow-y: auto;}
.overflow-x                                   {overflow-x: auto;}

.pos-relative                                { position: relative !important;}

.visibility-hidden                            {visibility: hidden;}
.visibility-collapse                          {visibility: collapse;}

.hide-for-print                               {@media print { display: none;}}
.hide-for-screen                              {@media screen {display: none;}}

/**
 * 6. Flex
 */
.noWrap                                       {flex-wrap: nowrap;}

// ~ Margin helper
$spacing-value: 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20, 25, 26, 27, 28, 29, 30, 35, 40;

@each $value in $spacing-value {
  .space-top-#{$value} {
    margin-top: #{$value/10}rem;
  }
  .space-right-#{$value} {
    margin-right: #{$value/10}rem;
  }
  .space-bottom-#{$value} {
    margin-bottom: #{$value/10}rem;
  }
  .space-left-#{$value} {
    margin-left: #{$value/10}rem;
  }
  .force-space-top-#{$value} {
    margin-top: #{$value/10}rem !important;
  }
  .force-space-right-#{$value} {
    margin-right: #{$value/10}rem !important;
  }
  .force-space-bottom-#{$value} {
    margin-bottom: #{$value/10}rem !important;
  }
  .force-space-left-#{$value} {
    margin-left: #{$value/10}rem !important;
  }

  @include breakpoint(small only) {
    .sm-space-top-#{$value} {
      margin-top: #{$value/10}rem;
    }
    .sm-space-right-#{$value} {
      margin-right: #{$value/10}rem;
    }
    .sm-space-bottom-#{$value} {
      margin-bottom: #{$value/10}rem;
    }
    .sm-space-left-#{$value} {
      margin-left: #{$value/10}rem;
    }
    .sm-force-space-top-#{$value} {
      margin-top: #{$value/10}rem !important;
    }
    .sm-force-space-right-#{$value} {
      margin-right: #{$value/10}rem !important;
    }
    .sm-force-space-bottom-#{$value} {
      margin-bottom: #{$value/10}rem !important;
    }
    .sm-force-space-left-#{$value} {
      margin-left: #{$value/10}rem !important;
    }
  }
}


// ~ Padding helper
$inner-spacing-value: $spacing-value;

@each $value in $inner-spacing-value {
  .inner-space-top-#{$value} {
    padding-top: #{$value/10}rem;
  }
  .inner-space-right-#{$value} {
    padding-right: #{$value/10}rem;
  }
  .inner-space-bottom-#{$value} {
    padding-bottom: #{$value/10}rem;
  }
  .inner-space-left-#{$value} {
    padding-left: #{$value/10}rem;
  }
  .force-inner-space-top-#{$value} {
    padding-top: #{$value/10}rem !important;
  }
  .force-inner-space-right-#{$value} {
    padding-right: #{$value/10}rem !important;
  }
  .force-inner-space-bottom-#{$value} {
    padding-bottom: #{$value/10}rem !important;
  }
  .force-inner-space-left-#{$value} {
    padding-left: #{$value/10}rem !important;
  }
}
