// -----------------------------------------------------------------------------
// This file contains all styles related to the MODAL component.
// -----------------------------------------------------------------------------

// ==== Custom Reveal for Mobile ==== //

html.is-reveal-open {
  overflow-y: auto !important;
}

.reveal-overlay {
  z-index: 99998 !important;
  overflow-y: auto !important;
  overflow: hidden;
  animation: fadeIn  240ms ease-out;

  @keyframes fadeIn {
    from { opacity: 0;}
    to { opacity: 1;}
  }

  @include breakpoint(small only) { overflow-y: hidden !important;}

  .reveal {
    height: auto !important;
    position: relative;
    margin-bottom: rem-calc(50px);
    border: none;
    border-radius: rem-calc(4px);
    padding: 0;
    overflow: visible;
    animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
    -webkit-overflow-scrolling: touch;

    .bandeau-label {
      position: absolute;
      bottom: rem-calc(16px);
      right: rem-calc(32px);
      transform: rotate(-35deg);
      color: white;
      font-size: rem-calc(11px);
      z-index: 1;
    }

    @include breakpoint(small only) {
      height: 100vh !important;
    }

    &.tiny {
      width: fit-content;
      top: calc(#{$height-header} + 24px) !important;

      @include breakpoint(small only) { top: 0 !important; width: 100% !important;}
      @include breakpoint(medium only) { width: 65%;}

      &.middle {
        top: 30%;
        transform: translate(-50%, -50%);
      }
    }

    &.small {
      width: 35%;
      top: calc(#{$height-header} + 24px) !important;

      @include breakpoint(small only) { top: 0 !important; width: 100% !important;}
      @include breakpoint(medium only) { width: 65%;}

      &.middle {
        top: 30%;
        transform: translate(-50%, -50%);
      }
    }

    &.medium {
      width: 65%;
      top: calc(#{$height-header} + 24px) !important;

      @include breakpoint(small only) { top: 0 !important; width: 100% !important;}
      @include breakpoint(medium only) { width: 75%;}

      &.middle {
        top: 30%;
        transform: translate(-50%, -50%);
      }
    }

    &.large {
      width: 85%;
      top: calc(#{$height-header} + 24px) !important;

      @include breakpoint(small only) { top: 0 !important; width: 100% !important;}
      @include breakpoint(medium only) { width: 85%;}

      &.middle {
        top: 30%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .modal-container {
    position: relative;
    display: block;
    height: 100%;

    &.modal-scroll-container {
      @include breakpoint(small only) {
        overflow-y: auto;
        height: calc(100vh - 48px) !important;
        padding-bottom: rem-calc(80px);
        -webkit-overflow-scrolling: touch;
      }
    }

    // ==== Part Modal

    .modal-header {
      padding: rem-calc(24px 16px 24px 24px);
    }

    .modal-content {
      font-size: rem-calc(16px);
      padding: 0 $app-view-gutter;

      @include breakpoint(small only) { height: auto;}
    }

    .modal-footer {
      padding: rem-calc(0 32px);
      text-align: right;
    }
  }
}

.modal-icon {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: rem-calc(100px);
  height: rem-calc(100px);
  margin: rem-calc(32px 24px);
  font-size: rem-calc(90px);
  color: $app-primary;
  border-radius: rem-calc(80px);
  border: rem-calc(2px) solid $app-primary;
  // box-shadow: 0 0 0 rem-calc(10px) mix(white, $app-primary, 80%);
  box-shadow: 0 0 0 rem-calc(10px) $app-primary;

  i::before {
    line-height: 2;
  }
}
