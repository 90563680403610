@import "~@ng-select/ng-select/scss/default.theme";

/**
 * Skin override of @ng-select/ng-select
 *
 * Init custom var
**/
$ngSelect-inputFontSize: 14px;
$ngSelect-inputColor: $primary;
$ngSelect-inputBgColor: white;
$ngSelect-inputColor-placeholderColor: $input-placeholder-color;
$ngSelect-inputBorderColor: $primary;
$ngSelect-inputBorder-Radius: $input-radius;
$ngSelect-inputBoxShadow: 0 1px 2px rgba($primary, 0.4);

$ngSelect-inputFocusBorderColor: $primary;
$ngSelect-inputFocusBoxShadow: 0 1px 2px rgba($primary, 0.5);

$ngSelect-disabled-bgColor: $input-background-disabled;
$ngSelect-disabled-color: mix($black, $grey-dark, 50%);

$ngSelect-clearColor : $app-error-color;

$ngSelect-optionHoverColor: white;
$ngSelect-optionHoverBgColor: $primary;
$ngSelect-optionSelectedColor: white;
$ngSelect-optionSelectedBgColor: $primary;

$ngSelect-multiple-optionColor: white;
$ngSelect-multiple-optionBgColor: $primary;
$ngSelect-multiple-optionCloseHoverBgColor: $primary;

/**
 * Override Foundation input styles that create side effect on ngSelect
**/
input[role="combobox"] {
  height: auto;
  line-height: normal;
  margin: 0;
  padding: 0;
  display: inline-block;
  font-size: rem-calc($ngSelect-inputFontSize);
}

/**
 * Add @ng-select/ng-select rtl mixin
**/
@mixin rtl {
  @at-root [dir="rtl"] #{&} {
    @content
  }
}

.ng-select {
  line-height: rem-calc(18px);

  &.ng-select-opened {
    > .ng-select-container {
      background: $ngSelect-inputBgColor;
      border-color: $ngSelect-inputFocusBorderColor;
      box-shadow: $ngSelect-inputFocusBoxShadow;

      &:hover {
        box-shadow: none;
      }

      .ng-arrow {
        top: -2px;
        border-color: transparent transparent $ngSelect-inputFocusBorderColor;
        border-width: 0 5px 5px;

        &:hover {
          border-color: transparent transparent $ngSelect-inputFocusBorderColor;
        }
      }
    }

    &.ng-select-bottom {
      > .ng-select-container {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    &.ng-select-top {
      > .ng-select-container {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
      }
    }
  }

  &.ng-select-focused {
    &:not(.ng-select-opened) > .ng-select-container {
      border-color: $ngSelect-inputFocusBorderColor;
      box-shadow: $ngSelect-inputFocusBoxShadow;
    }
  }

  &.ng-select-disabled {
    > .ng-select-container {
      background-color: $ngSelect-disabled-bgColor;
      color: $ngSelect-disabled-color;

      @media print {
        background-color: $ngSelect-inputBgColor !important;
      }
    }
  }

  .ng-has-value .ng-placeholder {
    display: none;
  }

  .ng-select-container {
    background-color: $ngSelect-inputBgColor;
    border-radius: rem-calc($ngSelect-inputBorder-Radius);
    min-height: rem-calc(38px);
    align-items: center;

    .ng-value-container {
      align-items: center;
      padding-left: 10px;
      @include rtl {
        padding-right: 10px;
        padding-left: 0;
      }

      .ng-value {
        font-size: rem-calc($ngSelect-inputFontSize);
      }

      .ng-placeholder {
        color: $ngSelect-inputColor-placeholderColor;
        font-size: rem-calc($ngSelect-inputFontSize);
      }
    }

    .ng-input {
      top: 0 !important;
      padding-left: 0 !important;
      padding-right: rem-calc(56px) !important;

      input {
        height: 100%;
        padding: 10px !important;
      }
    }
  }

  &.ng-select-single {
    .ng-select-container {
      height: rem-calc(36px);

      .ng-value-container {
        .ng-input {
          top: rem-calc(7px);
          left: 0;
          padding-left: rem-calc(8px);
          padding-right: rem-calc(48px);

          @include rtl {
            padding-right: rem-calc(8px);
            padding-left: rem-calc(48px);
          }
        }
      }
    }
  }

  &.ng-select-multiple {
    &.ng-select-disabled {
      > .ng-select-container .ng-value-container .ng-value {
        background-color: $ngSelect-disabled-color;
        border: 1px solid $ngSelect-disabled-color;

        .ng-value-label {
          padding: 0 5px;
        }
      }
    }

    .ng-select-container {

      .ng-input {
        input {
          margin: 0;
          padding: inherit !important;
        }
      }

      .ng-value-container {
        padding-top: 5px;
        padding-left: 7px;
        @include rtl {
          padding-right: 7px;
          padding-left: 0
        }

        .ng-value {
          margin-bottom: 5px;
          color: $ngSelect-multiple-optionColor;
          background-color: $ngSelect-multiple-optionBgColor;
          border-radius: 2px;
          margin-right: 5px;
          font-size: rem-calc($ngSelect-inputFontSize);

          @media print {
            color: $ngSelect-multiple-optionColor !important;

            & .ng-value-label {
              color: $ngSelect-multiple-optionColor !important;
            }
          }

          @include rtl {
            margin-right: 0;
            margin-left: 5px;
          }

          &.ng-value-disabled {
            background-color: $ngSelect-disabled-color;

            .ng-value-label {
              padding-left: 5px;
              @include rtl {
                padding-left: 0;
                padding-right: 5px;
              }
            }
          }

          .ng-value-label {
            display: inline-block;
            padding: rem-calc(1px 8px 1px 4px);
            font-size: rem-calc($ngSelect-inputFontSize - 2px);
          }

          .ng-value-icon {
            display: inline-block;
            padding: rem-calc(1px 4px);

            &:hover {
              background-color: $ngSelect-multiple-optionCloseHoverBgColor;
            }

            &.left {
              border-right: 1px solid $ngSelect-multiple-optionBgColor;
              @include rtl {
                border-left: 1px solid $ngSelect-multiple-optionBgColor;
                border-right: none;
              }
            }

            &.right {
              border-left: 1px solid $ngSelect-multiple-optionBgColor;
              @include rtl {
                border-left: 0;
                border-right: 1px solid $ngSelect-multiple-optionBgColor;
              }
            }
          }
        }

        .ng-input {
          padding: 0 0 3px 3px;
          @include rtl {
            padding: 0 3px 3px 0;
          }
        }

        .ng-placeholder {
          top: rem-calc(7px);
          padding-bottom: 5px;
          padding-left: 3px;
          @include rtl {
            padding-right: 3px;
            padding-left: 0;
          }
        }
      }
    }
  }

  .ng-clear-wrapper {
    color: $ngSelect-inputColor;

    &:hover .ng-clear {
      color: $ngSelect-clearColor;
    }
  }

  .ng-spinner-zone {
    padding: 5px 5px 0 0;

    @include rtl {
      padding: 5px 0 0 5px;
    }
  }

  .ng-arrow-wrapper {
    width: 25px;
    padding-right: 5px;
    @include rtl {
      padding-left: 5px;
      padding-right: 0;
    }

    @media print {
      display: none;
    }

    &:hover {
      .ng-arrow {
        border-top-color: $ngSelect-inputBorderColor;
      }
    }

    .ng-arrow {
      border-color: $ngSelect-inputBorderColor transparent transparent;
      border-style: solid;
      border-width: rem-calc(5px 5px 2.5px);
    }
  }
}

.ng-dropdown-panel {
  background-color: $ngSelect-inputBgColor;
  border: 1px solid $ngSelect-inputFocusBorderColor;
  box-shadow: 0 1px 0 rgba($ngSelect-inputFocusBorderColor, 0.06);
  left: 0;

  &.ng-select-bottom {
    top: 100%;
    border-bottom-right-radius: rem-calc($ngSelect-inputBorder-Radius);
    border-bottom-left-radius: rem-calc($ngSelect-inputBorder-Radius);
    border-top-color: $ngSelect-inputFocusBorderColor;
    margin-top: -1px;

    .ng-dropdown-panel-items {
      .ng-option {
        &:last-child {
          border-bottom-right-radius: rem-calc($ngSelect-inputBorder-Radius);
          border-bottom-left-radius: rem-calc($ngSelect-inputBorder-Radius);
        }
      }
    }
  }

  &.ng-select-top {
    bottom: 100%;
    border-top-right-radius: rem-calc($ngSelect-inputBorder-Radius);
    border-top-left-radius: rem-calc($ngSelect-inputBorder-Radius);
    border-bottom-color: $ngSelect-inputBorderColor;
    margin-bottom: -1px;

    .ng-dropdown-panel-items {
      .ng-option {
        &:first-child {
          border-top-right-radius: rem-calc($ngSelect-inputBorder-Radius);
          border-top-left-radius: rem-calc($ngSelect-inputBorder-Radius);
        }
      }
    }
  }

  .ng-dropdown-header {
    border-bottom: 1px solid $ngSelect-inputBorderColor;
    padding: 5px 7px;
  }

  .ng-dropdown-footer {
    border-top: 1px solid $ngSelect-inputBorderColor;
    padding: 5px 7px;
  }

  .ng-dropdown-panel-items {
    margin-bottom: 1px;

    .ng-optgroup {
      user-select: none;
      padding: 8px 10px;
      font-weight: 500;
      color: $ngSelect-inputColor;
      cursor: pointer;

      &.ng-option-disabled {
        cursor: default;
      }

      &.ng-option-marked {
        color: $ngSelect-optionHoverColor;
        background-color: $ngSelect-optionHoverBgColor;
      }

      &.ng-option-selected, &.ng-option-selected.ng-option-marked {
        background-color: $ngSelect-optionSelectedBgColor;
        font-weight: 600;
      }
    }

    .ng-option {
      background-color: $ngSelect-inputBgColor;
      color: $ngSelect-inputColor;
      padding: 8px 10px;

      &.ng-option-selected, &.ng-option-selected.ng-option-marked {
        color: $ngSelect-optionSelectedColor;
        background-color: $ngSelect-optionSelectedBgColor;

        .ng-option-label {
          font-weight: 600;
        }
      }

      &.ng-option-marked {
        color: $ngSelect-optionHoverColor;
        background-color: $ngSelect-optionHoverBgColor;
      }

      &.ng-option-disabled {
        color: $ngSelect-disabled-color;
      }

      &.ng-option-child {
        padding-left: 22px;
        @include rtl {
          padding-right: 22px;
          padding-left: 0;
        }
      }

      .ng-tag-label {
        font-weight: 400;
        padding-right: 5px;
        @include rtl {
          padding-left: 5px;
          padding-right: 0;
        }
      }
    }
  }

  @include rtl {
    direction: rtl;
    text-align: right;
  }
}

@media print {
  .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
    z-index: 10;
  }
}
